@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: #050505;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;
}

.inner2 {
  margin: auto;
  max-width: 1680px;
  padding-left: 24px;
  padding-right: 24px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 150px;
  padding-left: 24px;
  padding-right: 24px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "游ゴシック", YuGothic, "Noto Sans JP", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav.panelactive {
  background-color: #fff;
  padding: 80px 50px 50px 50px;
  transition: opacity .6s ease, visibility .6s ease;
}

#g-nav.panelactive .sp_contact {
  margin-top: 30px;
}

#g-nav {
  position: relative;
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 834px) {
  #g-nav .mainNav {
    display: block;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #g-nav .mainNav {
    display: block;
  }
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li a {
  text-align: center;
  padding: 10px 15px;
  display: block;
  position: relative;
  font-size: 1.25em;
  font-weight: bold;
  color: #050505;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  #g-nav .mainNav li a {
    padding: 10px;
  }
}

@media screen and (max-width: 834px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    text-align: left;
    position: relative;
    border-left: none;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    text-align: left;
    position: relative;
    font-weight: bold;
    border-left: none;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: opacity .6s ease, visibility .6s ease;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 834px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: opacity .6s ease, visibility .6s ease;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

#toggle .is-open {
  display: block;
}

.dropdown {
  width: 300px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.dropdown li:last-of-type a {
  border-bottom: none;
}

.dropdown li a {
  background-color: #3098f2;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #fff;
  padding: 15px !important;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #050505 !important;
  }
}

@media screen and (max-width: 834px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #050505 !important;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: #3098f2;
  box-shadow: -29px 28px 41px -49px #dcdada;
  z-index: 10000;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #fff;
  width: 50%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

.h-contact,
.sp_contact,
.fix {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 9999;
}

.h-contact a,
.sp_contact a,
.fix a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.87em;
  font-weight: bold;
  color: #050505;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .h-contact a,
  .sp_contact a,
  .fix a {
    padding: 20px;
    width: fit-content;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .h-contact a,
  .sp_contact a,
  .fix a {
    width: 100%;
    padding: 30px;
  }
}

@media screen and (max-width: 834px) {
  .h-contact a,
  .sp_contact a,
  .fix a {
    width: 100%;
    padding: 30px;
  }
}

.h-contact img,
.sp_contact img,
.fix img {
  margin-right: 10px;
}

.h-contact {
  padding-right: 15px;
}

@media screen and (max-width: 834px) {
  .h-contact {
    display: none;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .h-contact {
    display: none;
  }
}

.fix {
  position: fixed;
  bottom: 0;
  right: 0;
}

.sp_contact {
  display: none;
}

@media screen and (max-width: 834px) {
  .sp_contact {
    display: block;
  }
}

@media only screen and (min-width: 834px) and (max-width: 900px) {
  .sp_contact {
    display: block;
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  background-color: #fff;
}

.header {
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;
  height: fit-content;
  left: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.header--unpinned {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

.mainArea img {
  width: 100%;
}

.mainWrap {
  position: relative;
  z-index: 1;
}

.mainWrap .mainText {
  font-size: clamp(50px, 7vw, 70px);
  text-align: left;
  position: absolute;
  top: 20%;
  left: 10%;
  line-height: 1.2em;
  text-shadow: -14px 14px 14px #050505;
  color: #fff;
  font-weight: bold;
}

.subArea {
  background-position: center;
  background-size: cover;
  padding: 8rem 0;
}

@media screen and (max-width: 834px) {
  .subArea {
    padding: 3rem 0;
  }
}

.subArea h1 {
  text-align: center;
  font-size: clamp(30px, 4vw, 50px);
  font-weight: bold;
  line-height: 1.2em;
  color: #fff;
}

.subArea span {
  font-size: 1.12em;
  line-height: 2em;
  color: #ec0909;
  font-weight: 500;
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #050505;
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  color: #fff;
  background: url(../images/home/blue_ball_bg.jpg);
  background-size: cover;
  padding: 100px 24px 50px;
}

footer#global_footer .footer .ftTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .ftTop {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .ftTop .ft_L {
    margin-bottom: 30px;
  }
}

footer#global_footer .footer .ftTop .ft_R .h_tel {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer .footer .ftTop .ft_R .h_tel img {
  margin-right: 10px;
}

footer#global_footer .footer .ftTop .ft_R .telR {
  color: #fff;
  font-size: 2.8em;
}

footer#global_footer .footer .ftTop .ft_R .telR address {
  font-size: 20px;
  text-align: left;
  margin-top: 20px;
}

footer#global_footer .ftNav {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav {
    display: block;
  }
}

footer#global_footer .ftNav li {
  border-right: 3px solid #fff;
}

footer#global_footer .ftNav li:last-of-type {
  border-right: none;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav li {
    width: 100%;
    border-right: none;
  }
}

footer#global_footer .ftNav li a {
  font-weight: bold;
  font-size: 1.25em;
  text-align: left;
  padding: 10px;
  display: block;
  color: #fff;
  line-height: 1em;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav li a {
    background: #ec0909;
    border-radius: 50px;
    margin-bottom: 3%;
    padding: 15px;
    color: #fff;
    text-align: center;
  }
}

footer#global_footer .pagetop {
  position: fixed;
  right: 1%;
  bottom: 1%;
  background-color: #3098f2;
  border-radius: 50%;
  z-index: 9999;
}

footer#global_footer #copy {
  font-size: 1.25em;
  font-weight: bold;
  color: #fff !important;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
  font-size: 1.25em;
  font-weight: bold;
  padding: 20px 0;
  line-height: 1em;
}

@media screen and (max-width: 640px) {
  footer#global_footer #copy {
    font-size: 16px;
  }
  footer#global_footer #copy a {
    font-size: 16px;
  }
}

.title_01 h2 {
  font-size: clamp(40px, 5.5vw, 55px);
  line-height: 1em;
  position: relative;
  font-weight: bold;
}

.title_01 h2 .en {
  font-size: 25px;
  line-height: 1em;
  color: #3098f2;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}

.title_01 h2::after {
  content: "";
  display: block;
  width: 140px;
  height: 5px;
  background-color: #ec0909;
  margin: 15px auto 0;
}

.title_01 h2::before {
  content: url(../images/home/midashi_bg.png);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.title_02 h2 {
  font-size: clamp(24px, 2.4vw, 24px);
  line-height: 1.5em;
  font-family: 'Sorts Mill Goudy', serif;
}

.sub_title_01 h3 {
  font-size: 3.4em;
  line-height: 1.2em;
  font-weight: bold;
  color: #3098f2;
}

.motto {
  font-size: clamp(18px, 3.7vw, 37px);
  color: #fff;
  background: #fff;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ec0909, #fff);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ec0909, #fff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 90px 0 90px 144px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .motto {
    padding: 5rem;
  }
}

@media screen and (max-width: 834px) {
  .motto {
    padding: 5rem;
  }
}

.motto > div {
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .motto > div {
    line-height: 2em;
  }
}

.motto .big {
  font-size: clamp(24px, 4.8vw, 48px);
}

.motto .shadow {
  font-size: clamp(30px, 5.3vw, 53px);
  text-shadow: -14px 14px 14px #050505;
}

@media screen and (max-width: 640px) {
  .motto .shadow {
    display: block;
  }
}

.listText ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.listText li {
  width: calc((100% - 60px) / 3);
  float: none !important;
  overflow: inherit !important;
  position: relative;
  font-size: 4em;
  font-weight: bold;
  line-height: 1em;
  font-size: clamp(30px, 4vw, 50px);
  padding: 120px 0;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .listText li {
    width: calc((100% - 20px) / 2);
  }
}

@media screen and (max-width: 640px) {
  .listText li {
    width: 100%;
  }
}

.listText li::before {
  content: url(../images/home/ball_bg.png);
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.textCenter div {
  font-size: 2.1em;
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  line-height: 1.25em;
  font-weight: 400;
}

@media screen and (max-width: 640px) {
  .textCenter div {
    line-height: 2em;
  }
}

.contBox {
  justify-content: space-between;
}

.contBox .box {
  width: calc((100% - 60px) / 3);
  border: 5px solid #3098f2;
  background-color: #fff;
  padding: 40px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .contBox .box {
    width: calc((100% - 20px) / 2);
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .contBox .box {
    width: calc((100% - 20px) / 2);
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 640px) {
  .contBox .box {
    width: 100%;
    margin-bottom: 30px;
  }
}

.contBox .box h3 {
  font-size: 26px;
  font-weight: bold;
  color: #3098f2;
  border-bottom: 1px solid #3098f2;
  padding-bottom: 20px;
  margin-bottom: 60px;
  padding: 40px 0 10px 0;
  position: relative;
  margin-left: 110px;
}

.contBox .box h3::before {
  content: "";
  position: absolute;
  left: -100px;
  top: 50%;
  transform: translateY(-50%);
}

.contBox .box:nth-of-type(1) h3::before {
  content: url(../images/home/junior_icon.png);
}

.contBox .box:nth-of-type(2) h3::before {
  content: url(../images/home/kojin_icon.png);
}

.contBox .box:nth-of-type(3) h3::before {
  content: url(../images/home/ippan_icon.png);
}

.contBox .box article > div {
  font-size: 25px;
  line-height: 1.5em;
}

.newsWrap {
  box-shadow: 14px 14px 14px rgba(5, 5, 5, 0.5);
  padding: 50px;
  background-color: #fff;
}

.news {
  max-height: 230px;
  overflow-y: auto;
}

.news dt,
.news dd {
  border: none !important;
}

.news dt {
  justify-content: flex-start !important;
}

.news dl {
  padding: 20px 0;
  border-bottom: 4px solid #3098f2;
}

@media screen and (max-width: 834px) {
  .news dl {
    display: block !important;
  }
  .news dl dt,
  .news dl dd {
    width: 100% !important;
  }
}

.text div {
  line-height: 2em;
}

.cont_01 {
  background: #fff;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #9bd3d5, #fff);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #9bd3d5, #fff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding-bottom: 125px;
}

.cont_02 {
  color: #fff;
  position: relative;
}

.cont_02 h2 {
  font-size: 3.7em;
  font-weight: bold;
  line-height: 1.5em;
  position: relative;
  width: fit-content;
  margin-left: auto;
  font-size: clamp(24px, 6vw, 60px);
}

.cont_02 h2::before {
  content: url(../images/home/midashi_bg2.png);
  position: absolute;
  top: 0;
  left: 0;
}

.cont_02 article {
  max-width: 1080px;
  margin: auto;
  padding-right: 10px;
}

@media screen and (max-width: 834px) {
  .cont_02 article img {
    float: none !important;
  }
  .cont_02 article .imgL {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.cont_02 article > div {
  font-size: clamp(18px, 4.5vw, 45px);
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.cont_02 img {
  position: relative;
  z-index: 1;
  width: 48% !important;
}

.cont_02::before {
  content: "";
  width: 100%;
  height: 170px;
  background-color: #3098f2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 14px 14px 14px rgba(5, 5, 5, 0.5);
}

@media screen and (max-width: 834px) {
  .cont_02::before {
    height: 100%;
  }
}

@media screen and (max-width: 834px) {
  .cont_02 {
    padding: 30px;
  }
}

.cont_03 {
  background: url(../images/home/blue_ball_bg.jpg);
  background-size: cover;
  padding: 145px 24px;
}

.contBox2 {
  justify-content: space-between;
}

.contBox2 .box {
  width: calc((100% - 90px) / 2);
  border: 5px solid #fff;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .contBox2 .box {
    width: 100%;
  }
  .contBox2 .box:first-of-type {
    margin-bottom: 30px;
  }
}

.contBox2 .box article > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.contBox2 .box article > div a {
  color: #fff;
  font-size: clamp(30px, 4.5vw, 45px);
  font-weight: bold;
  text-shadow: -14px 14px 14px #050505;
  line-height: 1.25em;
}

.contBox2 .box article > div a .en {
  text-shadow: none;
  font-size: clamp(18px, 3.5vw, 35px);
}

.contactWrap {
  background: url(../images/home/contact_bg.jpg);
  background-size: cover;
  padding: 100px 24px;
}

.contactWrap .inner {
  padding: 50px;
  background-color: #fff;
}

.contactWrap .inner .h_tel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(26px, 5vw, 50px);
  font-weight: bold;
  color: #fff;
  background-color: #ec0909;
  padding: 20px;
  max-width: 570px;
  width: 100%;
  margin: auto;
}

.contactWrap .inner .h_tel img {
  margin-right: 10px;
}

.btn a, .btnR a {
  color: #fff;
  display: block;
  padding: 30px;
  font-size: 2.1em;
  transition: 0.5s;
  background-color: #ec0909;
  width: 100%;
  max-width: 450px;
  margin: auto;
  font-weight: 500;
}

.btnR a {
  margin: 0 0 0 auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1080px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1080px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1080px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1080px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1080px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1080px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: clamp(30px, 4vw, 40px);
  line-height: 1.2em;
  font-weight: bold;
  position: relative;
  padding: 15px 0 15px 5rem;
  border-bottom: 2px solid #3098f2;
}

.local_title_01 h2::before {
  content: url(../images/home/junior_icon2.png);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
}

.local_title_02 h2 {
  font-size: clamp(30px, 4vw, 40px);
  line-height: 1em;
  padding-bottom: 20px;
  font-weight: 400;
  font-family: 'Sorts Mill Goudy', serif;
  margin-bottom: 20px;
  position: relative;
}

.local_title_02 h2::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  background-color: #3098f2;
  margin: auto;
}

.local_title_02 span {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  color: #123d83;
}

.sub_title_02 h3 {
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: bold;
  color: #fff;
  padding: 15px;
  background-color: #3098f2;
  position: relative;
}

.sub_title_02 h3::before {
  content: url(../images/home/midashi_bg2.png);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.sub_title_02 h3 .deco {
  margin-left: 15px;
  font-size: 18px;
  border-bottom: 1px solid #fff;
}

.sub_title_03 h3 {
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: bold;
  color: #fff;
  padding: 15px;
  background-color: #ec0909;
  position: relative;
}

.sub_title_03 h3::before {
  content: url(../images/home/midashi_bg2.png);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.sub_title_04 h4 {
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: bold;
  color: #3098f2;
  border-bottom: 2px solid #3098f2;
  padding-bottom: 15px;
}

.sub_title_05 h4 {
  font-size: 1.25em;
  line-height: 1.2em;
  font-weight: bold;
  background-color: #CEEAF4;
  padding: 10px;
  display: inline-block;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL {
    max-width: 100% !important;
  }
}

.textLocal div {
  line-height: 2em;
  font-size: 1.8rem;
}

.local_cont_01, .local_cont_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont_01, .local_cont_02 {
    display: block;
  }
}

.local_cont_01 .flxL, .local_cont_02 .flxL {
  width: 48%;
}

.local_cont_01 .flxL img, .local_cont_02 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .flxL .imgL, .local_cont_02 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxL, .local_cont_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .flxR, .local_cont_02 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxR, .local_cont_02 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_02 {
  flex-direction: row-reverse;
}

.td01 td {
  width: 25%;
}

.td01 .td_L {
  background-color: #CEEAF4;
}

.td02 td div {
  text-align: center;
}

.td02 .td_L {
  background-color: #CEEAF4;
}

.td02 .td_top {
  background-color: #f7f7f7;
}

.td03 .td_L {
  background-color: #CEEAF4;
}

.td05 td div {
  text-align: center;
}

.td05 .td_top {
  background-color: #CEEAF4;
}

.td05 .td_top div {
  font-weight: bold;
}

.td05 .td_L {
  background-color: #f7f7f7;
}

.td05 .td_L div {
  font-weight: bold;
}

.kokoroe {
  background-color: #CEEAF4;
  padding: 15px;
}

.kokoroe h3 {
  font-size: 1.25em;
  font-weight: bold;
}

.listTokushoku li {
  position: relative;
  padding-left: 5rem;
  overflow: inherit !important;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.listTokushoku li:last-of-type {
  margin-bottom: 0;
}

.listTokushoku li .nb {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  background-color: #3098f2;
  width: 30px;
  height: 30px;
  text-align: center;
}

.courseBOX .box {
  width: 100%;
  border: 1px solid #ccc;
  padding: 15px;
}

.courseBOX h3 {
  font-size: 1.5em;
  font-weight: bold;
  border-bottom: 2px solid #ec0909;
  padding: 15px 15px 10px 15px;
  margin-bottom: 10px;
}

.dl01 dt {
  background-color: #CEEAF4 !important;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .dl01 dl {
    display: block !important;
  }
  .dl01 dl dt, .dl01 dl dd {
    width: 100% !important;
  }
}

.listVoice li {
  padding-left: 3rem;
  position: relative;
  margin-bottom: 20px;
}

.listVoice li:last-of-type {
  margin-bottom: 0;
}

.listVoice li .deco {
  display: block;
  text-align: right;
}

.listVoice li::before {
  content: "●";
  color: #3098f2;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}

.listVoice2 li {
  background-color: #f7f7f7;
  padding: 15px;
  margin-bottom: 20px;
}

.listVoice2 li:last-of-type {
  margin-bottom: 0;
}

.listVoice2 li .deco {
  display: block;
  text-align: right;
}

.imgBox {
  justify-content: space-between;
}

.imgBox .box {
  width: calc((100% - 40px) / 3);
}

.imgBox .box img {
  width: 100% !important;
}

@media screen and (max-width: 640px) {
  .imgBox .box {
    width: 100%;
    margin-bottom: 20px;
  }
  .imgBox .box:last-of-type {
    margin-bottom: 0;
  }
}

.btnBox {
  justify-content: space-between;
}

.btnBox .box {
  text-align: center;
  width: 48%;
  font-style: 1.8rem;
  margin-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .btnBox .box {
    width: 100%;
  }
  .btnBox .box:first-of-type {
    margin-bottom: 20px;
  }
}

.btnBox a {
  background-color: #ec0909;
  color: #fff;
  padding: 20px;
  font-weight: bold;
  display: block;
}

/* fadeUp */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeUpTrigger {
  opacity: 0;
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #ccc !important;
  padding: 15px 0;
}

.map iframe {
  width: 100%;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #ec0909;
  padding-bottom: 15px;
  font-weight: bold;
  margin: 50px 0 20px 0;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 50px;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

/*========= ローディング画面のためのCSS ===============*/
#splash {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  z-index: 9999999;
  text-align: center;
  color: #fff;
}

#splash-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*========= 画面遷移のためのCSS ===============*/
/*画面遷移アニメーション*/
.splashbg {
  display: none;
}

/*bodyにappearクラスがついたら出現*/
body.appear .splashbg {
  display: block;
  content: "";
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform: scaleY(0);
  background-color: #3098f2;
  /*伸びる背景色の設定*/
  animation-name: PageAnime;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes PageAnime {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  50% {
    transform-origin: top;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

/*画面遷移の後現れるコンテンツ設定*/
#containWrap {
  opacity: 0;
  /*はじめは透過0に*/
}

/*bodyにappearクラスがついたら出現*/
body.appear #containWrap {
  animation-name: PageAnimeAppear;
  animation-duration: 0.1s;
  /*アニメーション周期が完了するまでの所要時間*/
  animation-delay: 0.1s;
  /*アニメーションをいつ開始するか*/
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes PageAnimeAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
